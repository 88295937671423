import React from 'react';
import { Card, Typography, CardContent, CardMedia, Button, Box, Grid } from '@mui/material';
import bms from '../Images/bms.jpg';
import iit from '../Images/iit.jpeg';
import jain from '../Images/jain.jpg';
import ms from '../Images/ms.jpg';
import pes from '../Images/pes.jpeg';
import rv from '../Images/rv.jpg';

const cardData = [
  { title: 'MSRIT Bangalore', description: 'MSRIT is a leading engineering education institute in Bangalore, was established in the year 1962 by Dr. M.S. Ramaiah. This portal provides academic performance.', image: ms },
  { title: 'IIIT Bangalore', description: 'IIIT Bangalore was established in the year 1998 which offers M.Tech., M.Sc.& Integrated M.Tech. and P.G.D. programmes in information technology and related fields.', image: iit },
  { title: 'RV College of Engineering', description: 'At RV Institute of Technology and Management, we put great emphasis on incorporating Information and Communication Technology (ICT) in our education system.', image: rv },
  { title: 'BMS College of Engineering', description: 'BMS College of Engineering offers four years Bachelor of Engineering (B.E) programs in various branches and three years B.E. programs through lateral entry scheme.', image: bms },
  { title: 'PES University', description: 'PES University was established in the year 1988, it is a private NAAC-accredited university offering UG, PG, Executive and Research programs in various disciplines.', image: pes },
  { title: 'Jain University', description: 'Jain University is a private deemed university in Bengaluru, with a campus in Kochi, Kerala. It offers various UG and PG programs in engineering, management, sciences, arts.', image: jain },
];

const cardStyles = [
  { borderRadius: '8px' },
  { borderRadius: '12px' },
  { borderRadius: '8px' },
  { borderRadius: '15px' },
  { borderRadius: '10px' },
  { borderRadius: '10px' },
];

const Body = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: '40px' }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            color: '#0D637B',
            fontSize: { xs: '16px', sm: '25px', md: '36px', lg: '40px' },
          }}
        >
          Explore Top Engineering Colleges
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#0D637B',
            mt: 2,
            fontSize: { xs: '10px', sm: '16px', md: '18px', lg: '20px' },
          }}
        >
          Discover the best engineering colleges to kick-start your education and career!
        </Typography>
      </Box>

      <Grid container spacing={3} justifyContent="center">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                bgcolor: '#0D637B',
                width: '100%',
                height: '100%',
                borderRadius: cardStyles[index].borderRadius,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardMedia
                component="img"
                alt={card.title}
                height="150"
                image={card.image}
                title={card.title}
              />
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  gutterBottom
                  sx={{
                    color: 'white',
                    textAlign: 'center',
                    fontSize: { xs: '16px', sm: '18px', md: '18px' },
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  paragraph
                  sx={{
                    color: 'white',
                    fontSize: { xs: '10px', sm: '12px', md: '12px' },
                  }}
                >
                  {card.description}
                </Typography>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      bgcolor: '#FA7A47',
                      fontSize: { xs: '12px', sm: '14px', md: '12px' },
                      padding: '10px',
                    }}
                  >
                    APPLY NOW
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            bgcolor: '#0D637B',
            padding: '10px 20px',
            fontSize: { xs: '14px', sm: '16px', md: '18px' },
            minWidth: '300px',
            maxWidth: '400px',
          }}
        >
          EXPLORE MORE
        </Button>
      </Box>
    </Box>
  );
};

export default Body;
