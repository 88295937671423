import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Images/logo.jpg";
import { Facebook, Twitter, Instagram, Menu as MenuIcon } from "@mui/icons-material";

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => {
    setOpen(open);
  };

  return (
    <AppBar position="static" elevation={0}>
      {/* First Line: Diagonal Split */}
      <Box
        sx={{
          display: "flex",
          height: "40px",
          position: "relative",
          background: "#0D637B",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
        }}
      >
        {/* Left Side - #FA7A47 */}
        <Box
          sx={{
            width: { xs: "100%", sm: "20%" },
            backgroundColor: "#FA7A47",
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            clipPath: { sm: "polygon(0 0, 100% 0, 95% 100%, 0% 100%)" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "white", paddingX: {sx: "550px"},
              display: {xs:'flex',  md:"flex"}, marginLeft: {xs:"90px"},
              alignItems: "center",
              gap: "8px",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            Follow us:
            <Link href="#" color="inherit" sx={{ textDecoration: "none" }}>
              <Facebook />
            </Link>
            <Link href="#" color="inherit" sx={{ textDecoration: "none" }}>
              <Twitter />
            </Link>
            <Link href="#" color="inherit" sx={{ textDecoration: "none" }}>
              <Instagram />
            </Link>
          </Typography>
        </Box>

        {/* Right Side - #0D637B */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "250px", md: "100%" },
            backgroundColor: "#0D637B",
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "flex-end", xs: "center" },
            paddingRight: "15px",
            clipPath: { sm: "polygon(5% 0, 100% 0, 100% 100%, 0% 100%)" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: "10px", 
              fontSize: { xs: "9px", md: "14px" },
            }}
          >
            Call Now:<strong>&nbsp;+91 7052 101 786</strong>&nbsp;|&nbsp;Email:{" "}
            <strong>info@example.com</strong>
          </Typography>
        </Box>
      </Box>

      {/* Second Line: White Background */}
      <Toolbar
        sx={{
          backgroundColor: "white",
          justifyContent: "space-between",
          px: { xs: 1, sm: 2 },
          borderBottom: "1px solid #ddd",
          flexWrap: { xs: "wrap", sm: "nowrap" },
        }}
      >
        {/* Logo */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logo} // Replace with the actual path to your logo
            alt="Logo"
            style={{
              height: "65px", // Adjust height as needed
              marginRight: "10px", // Optional spacing
            }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "18px", sm: "24px" },
              fontFamily: "Arial, sans-serif",
              color: "#FA7A47",
            }}
          >
            {/* Add optional tagline or leave empty */}
          </Typography>
        </Box>

        {/* Navigation Links (desktop) */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: "20px",
            marginLeft: "250px",
          }}
        >
          <Link
            href="#"
            underline="none"
            sx={{
              color: "#0D637B",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "#FA7A47",
                color: "white",
                transform: "translateY(-2px)",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              },
              "&:active": {
                transform: "translateY(2px)",
                boxShadow: "none",
              },
              transition: "all 0.3s ease",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            Home
          </Link>
          <Link
            href="https://www.getmycollege.com/about-us"
            underline="none"
            sx={{
              color: "#0D637B",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "#FA7A47",
                color: "white",
                transform: "translateY(-2px)",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              },
              "&:active": {
                transform: "translateY(2px)",
                boxShadow: "none",
              },
              transition: "all 0.3s ease",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            About Us
          </Link>
          <Link
            href="https://www.getmycollege.com/contact-us"
            underline="none"
            sx={{
              color: "#0D637B",
              fontWeight: "500",
              "&:hover": {
                backgroundColor: "#FA7A47",
                color: "white",
                transform: "translateY(-2px)",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              },
              "&:active": {
                transform: "translateY(2px)",
                boxShadow: "none",
              },
              transition: "all 0.3s ease",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            Contact
          </Link>
        </Box>

        {/* Hamburger Menu for Mobile */}
        <IconButton
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={() => toggleDrawer(true)}
        >
          <MenuIcon
            sx={{
              fontSize: "30px",
              color: "#0D637B",
              cursor: "pointer", justifyContent: "flex-end", alignContent: "flex-end"
            }}
          />
        </IconButton>

        {/* Admission Button */}
        <Button
          variant="contained"
          href="https://www.getmycollege.com/colleges?state=karnataka"
          sx={{justifyContent: "center", alignContent: "center",
            backgroundColor: "#FA7A47",
            color: "white",
             marginLeft: {xs:"90px"}, 
             marginTop: {xs:"-70px", md: "5px"},
            textTransform: "none",
            fontWeight: "bold",
            fontSize: { xs: "9px", sm: "14px" },
            padding: { xs: "10px", sm: "10px 20px",md: "10px 20px" },
            "&:hover": {
              backgroundColor: "#E06B3E",
              transform: "translateY(-2px)",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            },
            "&:active": {
              transform: "translateY(2px)",
              boxShadow: "none",
            },
            transition: "all 0.3s ease",
          }}
        >
          TopEngineeringCollege-Bangalore
        </Button>
      </Toolbar>

      {/* Drawer for Mobile */}
      {/* Drawer for Mobile */}
<Drawer anchor="left" open={open} onClose={() => toggleDrawer(false)}>
  <Box
    sx={{ width: 250, position: "relative" }}
    role="presentation"
    onClick={() => toggleDrawer(false)}
    onKeyDown={() => toggleDrawer(false)}
  >
    {/* Drawer Header with Logo and Close Icon */}
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 20px",
        borderBottom: "1px solid #ddd",
      }}
    >
      {/* Drawer Logo */}
      <img
        src={logo} // Replace with the actual path to your logo
        alt="Logo"
        style={{ height: "40px" }}
      />
      {/* Close Icon */}
      <IconButton onClick={() => toggleDrawer(false)}>
        <CloseIcon sx={{ color: "#0D637B" }} />
      </IconButton>
    </Box>

    {/* Drawer Navigation Links */}
    <List>
      <ListItem button component="a" href="#">
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component="a" href="https://www.getmycollege.com/about-us">
        <ListItemText primary="About Us" />
      </ListItem>
      <ListItem button component="a" href="https://www.getmycollege.com/contact-us">
        <ListItemText primary="Contact" />
      </ListItem>
    </List>
  </Box>
</Drawer>

    </AppBar>
  );
};

export default Header;
