import React from 'react';
import { Typography, Box } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'; // Import the quote icon
import view from '../Images/view.jpg'; // Import the image

function Review() {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        height: {md:'80vh', sx:'60px'},
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens, row on larger screens
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '20px', md: '1px' }, // Adjust padding for different screen sizes
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          width: { xs: '150px', sm: '250px', md: '450px', lg: '450px' }, // Adjust width for different screen sizes
          height: { xs: '150px', sm: '250px', md: '450px', lg: '450px' }, // Adjust height for different screen sizes
          borderRadius: '50%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: { xs: '20px', md: '0' }, // Add margin bottom on small screens for spacing
        //   boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Add a subtle shadow for aesthetics
        }}
      >
        <img
          src={view}
          alt="Profile"
          style={{
            width: '700px', // Ensure the image fills the container
            height: '400px', marginLeft: '33px',
            objectFit: 'revert-layer', // Maintain the aspect ratio and fit properly
          }}
        />
      </Box>

      {/* Text Section */}
      <Box
        sx={{
          maxWidth: { xs: '100%', sm: '80%', md: '600px' }, // Adjust max width for different screen sizes
          textAlign: 'center',
          paddingX: { xs: '10px', sm: '30px', md: '60px' },
        }}
      >
        <FormatQuoteIcon
          sx={{
            fontSize: { xs: '30px', sm: '40px', md: '80px' },
            color: '#FA7A47',
          }}
        />
        <Typography
          sx={{
            fontFamily: 'Times New Roman',
            fontSize: { xs: '16px', sm: '18px', md: '22px', lg: '24px' }, // Adjust font size for readability
            color: '#0D637B',
            display: 'inline',
            paddingX: '10px',
            lineHeight: 1.6, // Add line-height for better readability
          }}
        >
          Our engineering college has earned outstanding reviews from students, alumni, and industry professionals. Known for its exceptional faculty, state-of-the-art facilities, and rigorous academic programs, it fosters an environment of innovation and personal growth. Students praise the supportive atmosphere and placements.
        </Typography>
        <FormatQuoteIcon
          sx={{
            fontSize: { xs: '30px', sm: '40px', md: '80px' },
            color: '#FA7A47',
          }}
        />
      </Box>
    </Box>
  );
}

export default Review;
