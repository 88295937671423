import React from "react";
import { Box, Typography, TextField, Button, Grid, Paper } from "@mui/material";
import grad from "../Images/grad.jpg";

const BannerWithForm = () => {
  return (
    <Box
      sx={{
        minHeight: "70vh", 
        backgroundImage: `url(${grad})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <Paper
        elevation={6}
        sx={{
          padding: { xs: "15px", sm: "20px", md: "20px" }, // Adjust padding based on screen size
          maxWidth: { xs: "60%", sm: "80%", md: "400px" }, // Responsive width
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        }}
      >
        {/* Banner Content */}
        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
          <Typography
            variant="h5" // Smaller heading size for mobile
            sx={{
              fontWeight: "bold",
              color: "#0D637B",
              marginBottom: { xs: "10px", sm: "15px" }, // Adjust margin
              fontSize: { xs: "20px", sm: "24px", md: "28px" }, // Responsive font size
            }}
          >
            Top Engineering College in Bangalore
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#333",
              marginBottom: { xs: "15px", sm: "20px" }, // Adjust margin
              fontSize: { xs: "12px", sm: "14px", md: "16px" }, // Responsive font size
            }}
          >
            Discover your future at one of the leading engineering institutions
            in Bangalore. Apply now to kickstart your career in engineering!
          </Typography>
        </Box>

        {/* Form */}
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {/* Name Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            {/* Email Field */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            {/* Phone Field */}
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                required
                size="small"
              />
            </Grid>
            {/* Course Selection */}
            <Grid item xs={12}>
              <TextField
                label="Interested Course"
                variant="outlined"
                fullWidth
                placeholder="E.g., Computer Science, Mechanical Engineering"
                required
                size="small"
              />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#FA7A47",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: "12px", sm: "14px", md: "16px" }, // Responsive font size
                  padding: { xs: "8px 12px", sm: "10px 15px" }, // Responsive padding
                  "&:hover": {
                    backgroundColor: "#E06B3E",
                  },
                }}
              >
                Submit Inquiry
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default BannerWithForm;
