import React from "react";
import { Box, Typography, Grid, Paper, Button, LinearProgress } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarRateIcon from "@mui/icons-material/StarRate";
import abt from "../Images/abt.png"; 

const AboutUsSection = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: { xs: "20px", sm: "30px", md: "50px" }, 
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4} sx={{ maxWidth: "1200px" }}>
        {/* Left Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{marginLeft: {xs: "1px",md: "-30px"},
              position: "relative",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <img
              src={abt}
              alt="Engineering Experience"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px",
                maxHeight: "400px", 
              }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: {md:"10px", xs: "5px" },
                left: {md:"20px", xs: "30px"}, 
                backgroundColor: "#0D637B",
                color: "#fff",
                padding: "8px 16px",
                borderRadius: "4px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold"}}>
                20+ Years Of Experience
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Right Content Section */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#FA7A47",
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: { xs: "14px", md: "16px" }, // Adjust font size
            }}
          >
            {/* About Us */}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "#0D637B",
              marginBottom: "16px",
              fontSize: { xs: "24px", md: "32px" }, // Adjust heading size
            }}
          >
            Empowering Minds, Inspiring Futures
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              marginBottom: "20px",
              fontSize: { xs: "14px", md: "16px" }, // Adjust body text size
            }}
          >
            At our engineering college, we strive to deliver excellence in education. 
            With a legacy of over two decades, we have cultivated a culture of innovation and success.
          </Typography>

          {/* Features */}
          <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
              <VerifiedIcon
                sx={{
                  color: "#0D637B",
                  marginRight: "8px",
                  fontSize: { xs: "20px", md: "28px" }, // Adjust icon size
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#0D637B",
                  fontSize: { xs: "16px", md: "18px" }, // Adjust text size
                }}
              >
                Certified Lecturers
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StarRateIcon
                sx={{
                  color: "#FA7A47",
                  marginRight: "8px",
                  fontSize: { xs: "20px", md: "28px" }, // Adjust icon size
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#0D637B",
                  fontSize: { xs: "16px", md: "18px" }, // Adjust text size
                }}
              >
                Quality Results
              </Typography>
            </Box>
          </Box>

          {/* Progress Bar */}
          <Typography
            variant="body1"
            sx={{
              color: "#333",
              marginBottom: "8px",
              fontSize: { xs: "14px", md: "16px" }, // Adjust font size
            }}
          >
            Student Satisfaction
          </Typography>
          <LinearProgress
            variant="determinate"
            value={89}
            sx={{
              height: "10px",
              borderRadius: "5px",
              backgroundColor: "#e0e0e0",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#FA7A47",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{
              marginTop: "5px",
              color: "#333",
              fontSize: { xs: "12px", md: "14px" }, // Adjust size
            }}
          >
            89%
          </Typography>

          {/* Read More Button */}
          {/* <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              backgroundColor: "#FA7A47",
              color: "white",
              padding: "10px 20px",
              fontWeight: "bold",
              fontSize: { xs: "14px", md: "16px" }, // Adjust size
              "&:hover": {
                backgroundColor: "#E06B3E",
              },
            }}
          >
            Read More
          </Button> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsSection;
