import React, { useEffect, useState } from "react";
import Header from "../src/Components/Header"
import Banner from "../src/Components/Banner"
import About from "../src/Components/About"
import Why from "../src/Components/Why"
import Footer from "../src/Components/Footer"
import Card from "../src/Components/Card"
import Review from "../src/Components/Review"
import AutoPopup from "./Components/Autopopup";
import { Grid } from "@mui/material";

function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(()=>{ const timeout = setTimeout(() => {
    handleOpenAutoPopup();
  }, 150000);})
   
    return (
      <Grid sx={{overflowX: 'hidden'}}>
        
        <Header/>
        <Banner/>
        <Card/>
        <About/>
        <Why/>
        <Review/>
        <Footer/>
        {/* <AutoPopup
          isOpen={isAutoPopupOpen}
          onClose={handleCloseAutoPopup}
          leadFlag="Organic"
        /> */}
       </Grid>
    );
  }
  export default App;