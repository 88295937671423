import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#0D637B',
        padding: { xs: '10px', sm: '15px', md: '20px' }, // Adjust padding for devices
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        marginTop: '30px', // Space above the footer
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontSize: { xs: '10px', sm: '14px', md: '16px' }, // Responsive font sizes
          color: 'white',
        }}
      >
        &copy; {new Date().getFullYear()} Top Engineering Colleges Bangalore. All Rights Reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
