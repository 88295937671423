import React from "react";
import { Box, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import why from "../Images/why.jpg"; // Replace with the actual path of your image

const WhyChooseUs = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0D637B", // Main background color
        color: "white",
        padding: { xs: "20px", sm: "30px", md: "40px" }, // Adjust padding for different devices
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Left Section: Content */}
        <Grid item xs={12} md={6}>
          <Box >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                marginBottom: "10px",
                fontSize: { xs: "14px", sm: "16px", md: "18px" },
              }}
            >
              Why Choose Us?
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: { xs: "22px", sm: "28px", md: "32px" },
              }}
            >
              At The Nexus Of Knowledge & Innovation
            </Typography>

            <List>
              {/* Flexibility */}
              <ListItem disableGutters>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: "#FA7A47" }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Flexibility"
                  secondary="Fusce fermentum gravida aliquam. Praesent metus luctus fringilla gravida sed."
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "18px" },
                  }}
                  secondaryTypographyProps={{
                    fontSize: { xs: "12px", md: "14px" }, color: "white",
                  }}
                />
              </ListItem>
              {/* Quality */}
              <ListItem disableGutters>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: "#FA7A47" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Quality"
                  secondary="Nullam faucibus semper dapibus, nibh felis consectetur."
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "18px" },
                  }}
                  secondaryTypographyProps={{
                    fontSize: { xs: "12px", md: "14px" }, color: "white",
                  }}
                />
              </ListItem>
              {/* Global */}
              <ListItem disableGutters>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: "#FA7A47" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Global"
                  secondary="Class aptent taciti sociosqu ad litora torquent per conubia nostra."
                  primaryTypographyProps={{
                    fontWeight: "bold",
                    fontSize: { xs: "16px", md: "18px" }
                  }}
                  secondaryTypographyProps={{
                    fontSize: { xs: "12px", md: "14px" }, color: "white",
                  }}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>

        {/* Right Section: Image with Text */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={8}
            sx={{
              position: "relative",
              backgroundColor: "#FA7A47",
              color: "white",
              padding: { xs: "20px", sm: "30px", md: "40px" },
              textAlign: "center",
              height:"400px"
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginBottom: "15px",
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
              }}
            >
              Your Future, Our Focus
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                marginBottom: "20px",
              }}
            >
              Innovate. Educate. Transform. Nurturing Leaders For Tomorrow.
            </Typography>
            <Box
              component="img"
              src={why} // Imported image used here
              alt="Students"
              sx={{
                // width: "600px", // Adjusts to container width
                width: { xs: "90%", sm: "75%", md: "400px" },
                height: { xs: "70%", sm: "75%", md: "300px" },
                borderRadius: "10px",
                margin: "0 auto",
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyChooseUs;
